<template>
  <tbody>
    <tr :class="{ 'uk-flex': isDailyresume }">
      <td
        :colspan="colspan"
        :class="{ 'uk-text-center': true, 'uk-margin-auto': isDailyresume }"
      >
        Data not found
      </td>
    </tr>
  </tbody>
</template>

<script>
export default {
  props: {
    colspan: {
      required: true,
      type: Number
    }
  },
  data() {
    return {
      pathName: this.$route.fullPath,
      isDailyresume: false
    }
  },
  mounted() {
    if (this.pathName.includes('daily-resume')) {
      return this.isDailyresume = true
    }
    return this.isDailyresume = false
  }
}
</script>
